.ProjectMain-owner,
.ProjectMain-notes {
  color: #f47b33;
  font-family: "Abel", sans-serif;
}

.ProjectMain-contributor {
  color: #3c98b1;
  font-family: "Abel", sans-serif;
}
.ProjectMain-title {
  font-family: "Abel", sans-serif;
  display: inline-block;
}
.ProjectMain-title:hover {
  cursor: pointer;
}

.ProjectMain {
  font-family: "Abel", sans-serif;
}
