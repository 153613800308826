.UserDashboard {
  margin-top: 1em;
  overflow-y: scroll;
  height: 92vh;
}

.details-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
