.UserDetailsCard {
  min-width: 315px;
  height: 15rem;
  display: flex;
  flex-direction: column;
  /* padding: 15px; */
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: lightgray;
  -webkit-box-shadow: 0px 0px 17px 9px rgba(145, 145, 145, 0.59);
  box-shadow: 0px 0px 17px 9px rgba(145, 145, 145, 0.59);
}

.UserDetailsCard-top {
  display: flex;
  justify-content: flex-start;
  color: orange;
}

.UserDetailsCard-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: black;
  margin-top: 1em;
  margin-right: 5px;
}

.UserDetailsCard-username {
  margin-left: 0;
}
@media screen and (max-width: 400px) {
  .UserDetailsCard {
    width: 80%;
    padding: 8px;
    margin-top: 30px;
  }
  .UserDetailsCard-top {
    justify-content: flex-start;
    margin-left: 10px;
    padding-left: 10px;
  }
  .UserDetailsCard-details {
    margin-right: 100px;
  }
  .UserDetailsCard-fullname {
    margin-left: 5px;
  }
}
