.profile-pic {
  width: 80px;
  position: relative;

  &-image {
    display: block;
    width: 100%;
  }

  &-upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  &-upload-overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
  }
  &-upload-overlay:hover > * {
    transform: translateY(0);
  }
}
