.btn-cancel {
  color: #ffffff !important;
  background-color: #f47b33 !important;
  border-color: #f47b33 !important;
}

.btn-outline-cancel,
.ant-btn-default {
  color: #f47b33 !important;
  border-color: #f47b33 !important;
  background-color: transparent !important;
}

.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active,
.btn-cancel.active,
.btn-outline-cancel:hover,
.btn-outline-cancel:focus,
.btn-outline-cancel:active,
.btn-outline-cancel.active,
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active,
.ant-btn-default.active,
.open .dropdown-toggle.btn-cancel {
  color: #ffffff !important;
  background-color: #c76125 !important;
  border-color: #c76125 !important;
}

.btn-outline-cancel:active,
.btn-outline-cancel.active,
.ant-btn-default:active .ant-btn-default.active .btn-cancel:active,
.btn-cancel.active,
.open .dropdown-toggle.btn-cancel {
  background-image: none;
}

.btn-cancel.disabled,
.btn-cancel[disabled],
fieldset[disabled] .btn-cancel,
.btn-cancel.disabled:hover,
.btn-cancel[disabled]:hover,
fieldset[disabled] .btn-cancel:hover,
.btn-cancel.disabled:focus,
.btn-cancel[disabled]:focus,
fieldset[disabled] .btn-cancel:focus,
.btn-cancel.disabled:active,
.btn-cancel[disabled]:active,
fieldset[disabled] .btn-cancel:active,
.btn-cancel.disabled.active,
.btn-cancel[disabled].active,
fieldset[disabled] .btn-cancel.active {
  background-color: #f47b33;
  border-color: #f47b33;
}

.btn-outline-cancel.disabled,
.btn-outline-cancel[disabled],
fieldset[disabled] .btn-outline-cancel,
.btn-outline-cancel.disabled:hover,
.btn-outline-cancel[disabled]:hover,
fieldset[disabled] .btn-outline-cancel:hover,
.btn-outline-cancel.disabled:focus,
.btn-outline-cancel[disabled]:focus,
fieldset[disabled] .btn-outline-cancel:focus,
.btn-outline-cancel.disabled:active,
.btn-outline-cancel[disabled]:active,
fieldset[disabled] .btn-outline-cancel:active,
.btn-outline-cancel.disabled.active,
.btn-outline-cancel[disabled].active,
fieldset[disabled] .btn-outline-cancel.active {
  background-color: transparent;
  border-color: #f47b33;
}

.btn-cancel .badge {
  color: #f47b33;
  background-color: #ffffff;
}

.btn-accept,
.badge-accept,
.ant-btn-primary {
  color: #ffffff !important;
  background-color: #3c98b1 !important;
  border-color: #3c98b1 !important;
}

.btn-outline-accept {
  color: #3c98b1 !important;
  background-color: transparent !important;
  border-color: #3c98b1 !important;
}

.btn-accept:hover,
.btn-accept:focus,
.btn-accept:active,
.btn-accept.active,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary.active,
.btn-outline-accept:hover,
.btn-outline-accept:focus,
.btn-outline-accept:active,
.btn-outline-accept.active,
.open .dropdown-toggle.btn-accept {
  color: #ffffff !important;
  background-color: #2f768a !important;
  border-color: #2f768a !important;
}

.btn-accept:active,
.btn-accept.active,
.ant-btn-primary:active,
.ant-btn-primary.active .btn-outline-accept:active,
.btn-outline-accept.active,
.open .dropdown-toggle.btn-accept {
  background-image: none;
}

.btn-accept.disabled,
.btn-accept[disabled],
fieldset[disabled] .btn-accept,
.btn-accept.disabled:hover,
.btn-accept[disabled]:hover,
fieldset[disabled] .btn-accept:hover,
.btn-accept.disabled:focus,
.btn-accept[disabled]:focus,
fieldset[disabled] .btn-accept:focus,
.btn-accept.disabled:active,
.btn-accept[disabled]:active,
fieldset[disabled] .btn-accept:active,
.btn-accept.disabled.active,
.btn-accept[disabled].active,
fieldset[disabled] .btn-accept.active {
  background-color: #215d6d;
  border-color: #215d6d;
}

.btn-accept .badge {
  color: #215d6d;
  background-color: #ffffff;
}

.accepted-true {
  background-color: lightgreen !important;
}

.accepted-false {
  background-color: #f8d7d9 !important;
}

.NewRequest {
  border-radius: 15px !important;
}
