html,
body {
  background-color: #292929;
}

::selection {
  background: #3c98b1; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #3c98b1; /* Gecko Browsers */
}
