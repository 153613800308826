.Navigation {
  background-color: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.nav-link.active {
  font-weight: bold;
  color: black;
}
.dropdown-divider {
  border-top: 1px solid #465055 !important;
}
