.ProjectCard {
  border-radius: 10px;
  flex: 110px;
  height: 110px;
  text-align: center;
  margin-right: 1.5em;
  margin-left: 1.5em;
  background-color: blanchedalmond;
  -webkit-box-shadow: 0px 0px 15px 9px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 15px 9px rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 540px) {
  .ProjectCard {
    margin-right: 0.13em;
    margin-left: 0.13em;
  }
}

.ProjectCard-top {
  background: #d3d3d3;
  background: -moz-linear-gradient(
    -45deg,
    #d3d3d3 0%,
    #d3d3d3 58%,
    #8c8c8c 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #d3d3d3 0%,
    #d3d3d3 58%,
    #8c8c8c 100%
  );
  background: linear-gradient(135deg, #d3d3d3 0%, #d3d3d3 58%, #8c8c8c 100%);
  height: 40px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #f47b33;
}

.ProjectCard-title {
  padding: 5px;
  font-size: 80%;
  font-weight: bolder;
  color: #616161;
  text-shadow: #e0e0e0 1px 1px 0;
}

.ProjectCard-details {
  background: #144372;
  background: -moz-linear-gradient(
    45deg,
    #144372 0%,
    #1c6ea4 50%,
    #144372 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #144372 0%,
    #1c6ea4 50%,
    #144372 100%
  );
  background: linear-gradient(45deg, #144372 0%, #1c6ea4 50%, #144372 100%);
  height: 70px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: small;

  justify-content: space-evenly;
}
.ProjectCard-updated {
  background: #144372;
  background: -moz-linear-gradient(
    45deg,
    #144372 0%,
    #1c6ea4 50%,
    #144372 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #144372 0%,
    #1c6ea4 50%,
    #144372 100%
  );
  background: linear-gradient(45deg, #144372 0%, #1c6ea4 50%, #144372 100%);
  height: 70px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: lightgray;
}

.ProjectCard-owner {
  color: #f47b33;
}

.ProjectCard-small {
  font-size: 50%;
}
