@import "~antd/dist/antd.css";
.Avatar {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  font-size: 35px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: lightgray;
  text-align: center;
  background: #215d6d;
  cursor: pointer;

  &-picture {
    border-radius: 50%;
    border: 2px orange solid;
  }
  &-img-wrapper {
    width: 60px;
    height: 60px;
  }
}
